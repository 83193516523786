import React, {useEffect, useState} from 'react';
import {useLocation} from '@reach/router';

export function NotFound() {
    const location = useLocation();
    const isLunii = !(location?.host?.includes('stelii.com') || location?.pathname?.includes('es-es'));
    return (
        <div style={{display: 'block', backgroundColor: '#FFFFFF', position: 'fixed', zIndex:100000, top: 0, left: 0, height: '100vh', width: '100vw'}}>
            <div style={{padding: '100px', width: '100%', height: '100%', boxSizing: 'border-box'}}>
                <h1>Lunii</h1>
                <p>OUPS&nbsp;! Vous êtes tombés dans un cul-de-sac. Merci de retourner sur <a href={`https://${isLunii ? 'lunii' : 'stelii'}.com`} rel={'noopener noreferrer'}>l'accueil de {`${isLunii ? 'Lunii' : 'Stelii'}`}.com</a> ou d'utiliser le {`${isLunii ? 'Lunii' : 'Stelii'}store`} le temps que nos lutins règlent ce souci&nbsp;!</p>
                <p>WOPS&nbsp;! Seems like you found yourself in a dead-end. Please go back to the <a href={`https://${isLunii ? 'lunii' : 'stelii'}.com`} rel={'noopener noreferrer'}>{`${isLunii ? 'Lunii' : 'Stelii'}`}.com homepage</a> or use the {`${isLunii ? 'Lunii' : 'Stelii'}store`} while our elves solve this issue&nbsp;!</p>
            </div>
        </div>
    );
}

export default NotFound;
